export const leadSource = [
  "Corpseed Website",
  "Facebook",
  "Instagram",
  "IVR",
  "Person Reference",
  "Whatsapp",
  "Law Zoom website",
  "Other",
]

export const userDepartment = ["Sales", "IT", "Quality", "HR"]
