// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 12px 6px;
}

.profile-text-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.avatar-container {
  position: relative;
  width: 62px;
  height: 62px;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.edit-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 20px;
  cursor: pointer;
}

.avatar-container:hover .edit-icon {
  opacity: 1;
}

.btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/components/ProfileDrawer.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;AACJ;;AAEE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,iBAAA;EACA,uBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,MAAA;EACA,QAAA;EACA,SAAA;EACA,OAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,8BAAA;EACA,YAAA;EACA,kBAAA;EACA,UAAA;EACA,wBAAA;EACA,eAAA;EACA,eAAA;AACJ;;AAEE;EACE,UAAA;AACJ;;AAEE;EACE,WAAA;EACA,aAAA;EACA,yBAAA;AACJ","sourcesContent":[".profile-container{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    padding: 12px 6px;\n}\n\n.profile-text-container{\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n}\n\n.avatar-container {\n    position: relative;\n    width: 62px;\n    height: 62px;\n  }\n  \n  .avatar {\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n    object-fit: cover;  \n    object-position: center;\n  }\n  \n  .edit-icon {\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: rgba(0, 0, 0, 0.5);\n    color: white;\n    border-radius: 50%;\n    opacity: 0;\n    transition: opacity 0.3s;\n    font-size: 20px; \n    cursor: pointer;\n  }\n  \n  .avatar-container:hover .edit-icon {\n    opacity: 1;\n  }\n\n  .btn-container{\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
