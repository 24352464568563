// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lead-module .filter-fields {
  padding: 20px;
  border: 1px solid #000000;
  display: flex;
  justify-content: space-between;
}
.lead-module .filter-fields .single-fields {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.lead-module .filter-fields .single-fields button {
  border: none;
  outline: none;
  background: transparent;
}
.lead-module .bottom-line {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 3px;
  border: 1px solid #fff;
  bottom: 20px;
  background-color: #fafafa;
}`, "",{"version":3,"sources":["webpack://./src/Main/Sales/Leads/LeadsModule.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,yBAAA;EACA,aAAA;EACA,8BAAA;AAAJ;AACI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;AACN;AAAM;EACE,YAAA;EACA,aAAA;EACA,uBAAA;AAER;AAEE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EACA,sBAAA;EACA,YAAA;EACA,yBAAA;AAAJ","sourcesContent":[".lead-module {\n  .filter-fields {\n    padding: 20px;\n    border: 1px solid #000000;\n    display: flex;\n    justify-content: space-between;\n    .single-fields {\n      font-size: 14px;\n      font-weight: 400;\n      line-height: 16px;\n      button {\n        border: none;\n        outline: none;\n        background: transparent;\n      }\n    }\n  }\n  .bottom-line{\n    display: flex;\n    align-items: center;\n    gap: 20px;\n    padding: 3px;\n    border: 1px solid #fff;\n    bottom: 20px; \n    background-color: #fafafa;\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
