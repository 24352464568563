// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-max-img {
  height: 400px;
  width: 450px;
  border-radius: 5px;
  border: 1px solid #d5d5d5;
  box-shadow: 1px 10px 20px rgba(0, 0, 0, 0.1);
}
.side-max-img img {
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 576px) {
  .side-max-img {
    width: 250px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/HomeImageComp.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,4CAAA;AACJ;AAAQ;EACA,YAAA;EACA,WAAA;AAER;;AAEA;EACI;IACI,YAAA;EACN;AACF","sourcesContent":[".side-max-img{\n    height: 400px;\n    width: 450px;\n    border-radius: 5px;\n    border: 1px solid #d5d5d5;\n    box-shadow: 1px 10px 20px rgba(0,0,0,.1);\n        img{\n        height: 100%;\n        width: 100%;\n    }\n}\n\n@media only screen and (max-width: 576px){\n    .side-max-img{\n        width: 250px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
