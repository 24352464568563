// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-boxes {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  flex-direction: column;
  gap: 10px;
}
.main-boxes .circle-tik {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid #00ff87;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00ff87;
}
.main-boxes .circle-tik .check-icon {
  font-size: 20px;
  font-size: 35px;
  font-weight: 600;
  color: #fff;
}
.main-boxes .update-message {
  margin: 5px 0px;
  font-size: 26px;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/Login/PaswordUpdateMessage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,sBAAA;EACA,SAAA;AACF;AAAE;EACE,aAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;AAEJ;AADI;EACE,eAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AAGN;AAAE;EACE,eAAA;EACA,eAAA;EACA,gBAAA;AAEJ","sourcesContent":[".main-boxes {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  min-height: 70vh;\n  flex-direction: column;\n  gap: 10px;\n  .circle-tik {\n    height: 100px;\n    width: 100px;\n    border-radius: 50%;\n    border: 1px solid #00ff87;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background-color: #00ff87;\n    .check-icon {\n      font-size: 20px;\n      font-size: 35px;\n      font-weight: 600;\n      color: #fff;\n    }\n  }\n  .update-message {\n    margin: 5px 0px;\n    font-size: 26px;\n    font-weight: 400;\n  }\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
