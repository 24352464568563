// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  display: flex;
  align-items: center;
  gap: 5px;
}
.logo .erp-image {
  height: 55px;
  width: 90px;
}
.logo .erp-image .main-logo-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.logo .erp-text {
  font-size: 25px;
  color: #093252;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/Login/MainLoginRoute.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;AAAI;EACI,YAAA;EACA,WAAA;AAER;AADQ;EACI,YAAA;EACA,WAAA;EACA,iBAAA;AAGZ;AAAI;EACI,eAAA;EACA,cAAA;EACA,gBAAA;AAER","sourcesContent":[".logo{\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    .erp-image{\n        height: 55px;\n        width: 90px;\n        .main-logo-image{\n            height: 100%;\n            width: 100%;\n            object-fit: cover;\n        }\n    }\n    .erp-text{\n        font-size: 25px;\n        color:  #093252;\n        font-weight: 400;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
