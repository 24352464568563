// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inbox-page .inbox-top-btn .tab-btn {
  border: 1px solid #000000;
  padding: 10px 20px;
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  border: none;
  margin-right: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.inbox-page .inbox-top-btn .tab-btn:hover {
  text-decoration: none;
}
.inbox-page .inbox-top-btn .tab-btn.active {
  background-color: #2B62F9;
  color: #ffffff;
}

.lead-count {
  color: #fff;
  background-color: green;
  padding: 10px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Main/Sales/Inbox/InboxPage.scss","webpack://./CommonColors.scss"],"names":[],"mappings":"AAII;EACE,yBAAA;EACA,kBAAA;EACA,cCNE;EDOF,eAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,oCAAA;EACA,kBAAA;AAHN;AAIM;EACE,qBAAA;AAFR;AAKI;EACE,yBCjBK;EDkBL,cCpBE;ADiBR;;AAQA;EACE,WAAA;EACA,uBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AALF","sourcesContent":["@import \"../../../../CommonColors.scss\";\n\n.inbox-page {\n  .inbox-top-btn {\n    .tab-btn {\n      border: 1px solid $black;\n      padding: 10px 20px;\n      color: $black;\n      font-size: 13px;\n      font-weight: 400;\n      border: none;\n      margin-right: 5px;\n      border: 1px solid rgba(0,0,0,.2);\n      border-radius: 5px;\n      &:hover {\n        text-decoration: none;\n      }\n    }\n    .tab-btn.active {\n      background-color: $mainBlue;\n      color: $white;\n    }\n  }\n}\n\n.lead-count{\n  color: #fff;\n  background-color: green;\n  padding: 10px;\n  height: 12px;\n  width: 12px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-size: 12px;\n}\n","$white: #ffffff;\n$black: #000000;\n$mainBlue: #2B62F9;\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
