// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mini-box {
  padding: 40px;
  border: 1px solid gray;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 4px;
  transition: all 1s ease-in-out;
  background-color: #383838;
}
.mini-box:hover {
  position: relative;
  top: -4px;
  border: 1px solid #2d69e6;
}
.mini-box p {
  margin: 0;
  font-size: 30px;
}
.mini-box .mini-text {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}`, "",{"version":3,"sources":["webpack://./src/components/boxes/ContentBox.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EACA,kBAAA;EACA,8BAAA;EACA,yBAAA;AACJ;AAAI;EACI,kBAAA;EACA,SAAA;EACA,yBAAA;AAER;AACI;EAEI,SAAA;EACA,eAAA;AAAR;AAEI;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;AAAR","sourcesContent":[".mini-box{\n    padding: 40px;\n    border: 1px solid gray;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    gap: 15px;\n    border-radius: 4px;\n    transition: all 1s ease-in-out;\n    background-color: #383838;\n    &:hover{\n        position: relative;\n        top: -4px;\n        border: 1px solid #2d69e6;\n\n    }\n    p{\n\n        margin: 0;\n        font-size: 30px;\n    }\n    .mini-text{\n        font-size: 16px;\n        font-weight: 500;\n        margin-bottom: 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
