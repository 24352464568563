// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tell-us-btn {
  padding: 12px 24px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  background: #093252;
  border-radius: 4px;
}

@media only screen and (max-width: 576px) {
  .tell-us-btn {
    padding: 8px 16px;
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Home/SubmitQuery.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE;IACE,iBAAA;IACA,eAAA;EACF;AACF","sourcesContent":[".tell-us-btn {\n  padding: 12px 24px;\n  border: none;\n  outline: none;\n  font-size: 16px;\n  font-weight: 600;\n  color: #fff;\n  background: #093252;\n  border-radius: 4px;\n}\n\n@media only screen and (max-width: 576px) {\n  .tell-us-btn {\n    padding: 8px 16px;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
