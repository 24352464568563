// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table-container {
  position: relative;
  overflow: hidden;
}
.table-container .scroll-button {
  position: absolute;
  top: 45%;
  transform: translateY(-45%);
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  border: none;
  display: none;
  z-index: 10;
}
.table-container .scroll-left {
  left: 0;
}
.table-container .scroll-right {
  right: 0;
}
.table-container:hover .scroll-button {
  display: block;
}

.ant-table-body {
  padding: 0;
}

.table-container .ant-table-body {
  max-height: 70vh;
}
@media (max-width: 1366px) {
  .table-container .ant-table-body {
    max-height: 40vh;
  }
}
@media (min-width: 1367px) and (max-width: 1920px) {
  .table-container .ant-table-body {
    max-height: 70vh;
  }
}
@media (min-width: 1921px) {
  .table-container .ant-table-body {
    max-height: 70vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/CommonTable.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;;AAEA;EACE,kBAAA;EACA,gBAAA;AACF;AAAE;EACE,kBAAA;EACA,QAAA;EACA,2BAAA;EACA,oCAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,WAAA;AAEJ;AACE;EACE,OAAA;AACJ;AAEE;EACE,QAAA;AAAJ;AAGE;EACE,cAAA;AADJ;;AAKA;EACE,UAAA;AAFF;;AAQE;EACE,gBAAA;AALJ;AASE;EACE;IACE,gBAAA;EAPJ;AACF;AAWE;EACE;IACE,gBAAA;EATJ;AACF;AAaE;EACE;IACE,gBAAA;EAXJ;AACF","sourcesContent":[".table-footer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n\n.table-container {\n  position: relative;\n  overflow: hidden;\n  .scroll-button {\n    position: absolute;\n    top: 45%;\n    transform: translateY(-45%);\n    background-color: rgba(0, 0, 0, 0.2);\n    color: white;\n    border: none;\n    display: none; \n    z-index: 10;\n  }\n\n  .scroll-left {\n    left: 0;\n  }\n\n  .scroll-right {\n    right: 0;\n  }\n\n  &:hover .scroll-button {\n    display: block; \n  }\n}\n\n.ant-table-body {\n  padding: 0; \n  // scroll-behavior: smooth;\n}\n\n\n.table-container {\n  .ant-table-body {\n    max-height: 70vh; \n  }\n\n\n  @media (max-width: 1366px) {\n    .ant-table-body {\n      max-height: 40vh; \n    }\n  }\n\n \n  @media (min-width: 1367px) and (max-width: 1920px) {\n    .ant-table-body {\n      max-height: 70vh; \n    }\n  }\n\n\n  @media (min-width: 1921px) {\n    .ant-table-body {\n      max-height: 70vh; \n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
