// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.h-heading {
  font-size: 27px;
  font-weight: 700;
}

.h-para {
  font-size: 18px;
  line-height: 30px;
  text-align: justify;
}

@media only screen and (max-width: 576px) {
  .h-heading {
    font-size: 24px;
  }
  .h-para {
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/small/SmallData.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AACF;;AAEA;EACE;IACE,eAAA;EACF;EAEA;IACE,eAAA;EAAF;AACF","sourcesContent":[".h-heading {\n  font-size: 27px;\n  font-weight: 700;\n}\n\n.h-para {\n  font-size: 18px;\n  line-height: 30px;\n  text-align: justify;\n}\n\n@media only screen and (max-width: 576px) {\n  .h-heading {\n    font-size: 24px;\n  }\n\n  .h-para {\n    font-size: 16px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
