// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.estimate-box {
  padding: 20px;
}
.estimate-box .center-box {
  width: 75%;
  border: 1px solid rgb(209, 205, 205);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.02);
  margin: auto;
  padding: 20px;
}
.estimate-box .center-box .estimate-heading {
  font-size: 18px;
  text-align: center;
  color: #262e39;
}`, "",{"version":3,"sources":["webpack://./src/Main/Sales/Leads/EstimateCreatePage.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AAAI;EACI,UAAA;EACA,oCAAA;EACA,2CAAA;EACA,YAAA;EACA,aAAA;AAER;AADQ;EACI,eAAA;EACA,kBAAA;EACA,cAAA;AAGZ","sourcesContent":[".estimate-box{\n    padding: 20px;\n    .center-box{\n        width: 75%;\n        border: 1px solid rgb(209, 205, 205);\n        box-shadow: 0px 2px  5px rgba(0,0,0,.02);\n        margin: auto;\n        padding: 20px;\n        .estimate-heading{\n            font-size: 18px;\n            text-align: center;\n            color: #262e39;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
