// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-style {
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  padding: 4px 8px;
  background-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/button/ButtonWithIcon.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,YAAA;EACA,aAAA;EACA,gBAAA;EACA,6BAAA;AACJ","sourcesContent":[".icon-style{\n    font-size: 14px;\n    font-weight: 500;\n    border: none;\n    outline: none;\n    padding: 4px 8px;\n    background-color: transparent;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
