import React from "react"
import HeroSection from "./HeroSection"
import SubmitQuery from "./SubmitQuery"
import SideNewSection from "./SideNewSection"
import JumpStart from "./JumpStart"
const FrontMainPage = () => {
  return (
    <div >
      <HeroSection />
      <JumpStart />
      <SideNewSection />
      <SubmitQuery />
    </div>
  )
}

export default FrontMainPage
