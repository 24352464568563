// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-web-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
}
.main-web-logo .main-nav-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  list-style-type: none;
  margin-bottom: 0px;
}
.main-web-logo .main-nav-items .single-link {
  font-size: 18px;
  color: #000;
  font-weight: 400;
}
.main-web-logo .main-nav-items .single-link:hover {
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Home/HomeNavbar.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,qBAAA;EACA,kBAAA;AAEJ;AADI;EACE,eAAA;EACA,WAAA;EACA,gBAAA;AAGN;AAFM;EACE,qBAAA;AAIR","sourcesContent":[".main-web-logo {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px 10px;\n  .main-nav-items {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 20px;\n    list-style-type: none;\n    margin-bottom: 0px;\n    .single-link {\n      font-size: 18px;\n      color: #000;\n      font-weight: 400;\n      &:hover {\n        text-decoration: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
