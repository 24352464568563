// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lead-box {
  padding: 8px 0px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Main/Setting/LeadStatus/LeadStatusPage.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,QAAA;AACJ","sourcesContent":[".lead-box{\n    padding: 8px 0px;\n    display: flex;\n    justify-content: flex-end;\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
