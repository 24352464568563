// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.setting {
  display: grid;
  grid-template-columns: 200px 1fr;
}
.setting .side-links {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  min-height: calc(100vh - 60px);
  padding: 15px;
}
.setting .side-data {
  padding: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Main/Setting/SettingMainPage.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gCAAA;AACF;AAAE;EACE,0CAAA;EACA,8BAAA;EACA,aAAA;AAEJ;AAAE;EACE,aAAA;AAEJ","sourcesContent":[".setting {\n  display: grid;\n  grid-template-columns: 200px 1fr;\n  .side-links {\n    border-right: 1px solid rgba(0, 0, 0, 0.2);\n    min-height: calc(100vh - 60px);\n    padding: 15px;\n  }\n  .side-data {\n    padding: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
