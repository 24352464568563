// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jump-section {
  background-color: #000;
  color: #fff;
}
.jump-section .jump-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff !important;
}
.jump-section .jump-boxes {
  display: grid;
  grid-template-columns: 250px 250px 250px;
  justify-content: center;
  padding: 20px 0px;
  gap: 10px;
}

@media only screen and (max-width: 768px) {
  .jump-section .jump-boxes {
    grid-template-columns: 250px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Home/JumpStart.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,WAAA;AACJ;AAAI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,sBAAA;AAER;AAAI;EACI,aAAA;EACA,wCAAA;EACA,uBAAA;EACA,iBAAA;EACA,SAAA;AAER;;AAGA;EAEQ;IACI,4BAAA;EADV;AACF","sourcesContent":[".jump-section{\n    background-color: #000;\n    color: #fff;\n    .jump-text{\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        flex-direction: column;\n        color: #fff !important;\n    }\n    .jump-boxes{\n        display: grid;\n        grid-template-columns: 250px 250px 250px;\n        justify-content: center;\n        padding: 20px 0px;\n        gap: 10px;\n    }\n}\n\n\n@media only screen and (max-width: 768px){\n    .jump-section{\n        .jump-boxes{\n            grid-template-columns: 250px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
