// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page {
  background-color: #2B62F9;
  color: #ffffff;
  display: flex;
  height: 100vh;
}
.main-page .side-nav {
  width: 200px;
  position: sticky;
  top: 0;
  overflow: auto;
  padding: 0px;
  background-color: #093252;
}
.main-page .side-nav::-webkit-scrollbar {
  display: none;
}
.main-page .right-data {
  background-color: #ffffff;
  flex: 10 1;
  color: #000000;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/Main/MainPage.scss","webpack://./CommonColors.scss"],"names":[],"mappings":"AAEA;EACE,yBCDS;EDET,cCJM;EDKN,aAAA;EACA,aAAA;AADF;AAEE;EACE,YAAA;EACA,gBAAA;EACA,MAAA;EACA,cAAA;EACA,YAAA;EACA,yBAAA;AAAJ;AACI;EACE,aAAA;AACN;AAEE;EACE,yBCnBI;EDoBJ,UAAA;EACA,cCpBI;EDqBJ,cAAA;AAAJ","sourcesContent":["@import \"../../CommonColors.scss\";\n\n.main-page {\n  background-color: $mainBlue;\n  color: $white;\n  display: flex;\n  height: 100vh;\n  .side-nav {\n    width: 200px;\n    position: sticky;\n    top: 0;\n    overflow: auto;\n    padding: 0px;\n    background-color: #093252;\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n  .right-data {\n    background-color: $white;\n    flex: 10;\n    color: $black;\n    overflow: auto;\n  }\n}\n","$white: #ffffff;\n$black: #000000;\n$mainBlue: #2B62F9;\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
