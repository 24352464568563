// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dragger-submit-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 12px 0px;
}

.file-upload-container {
  border: 1px dashed #d9d9d9;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  transition: border-color 0.3s;
}

.file-upload-container:focus-within {
  border-color: #1890ff; /* Change to your active border color */
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); /* Optional shadow effect */
}

.file-upload-container p {
  margin: 0;
}

.upload-button-container {
  text-align: center;
  margin-bottom: 20px;
}

.uploaded-files-list {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
}

.uploaded-files-list li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.uploaded-files-list a {
  text-decoration: none;
  color: #1890ff; /* Link color */
}

.uploaded-files-list a:hover {
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/Main/Sales/Leads/BulkFileUpload.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,yBAAA;EACA,iBAAA;AACJ;;AAIA;EACI,0BAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,6BAAA;AADJ;;AAIE;EACE,qBAAA,EAAA,uCAAA;EACA,6CAAA,EAAA,2BAAA;AADJ;;AAIE;EACE,SAAA;AADJ;;AAIE;EACE,kBAAA;EACA,mBAAA;AADJ;;AAIE;EACE,SAAA;EACA,UAAA;EACA,gBAAA;EACA,YAAA;AADJ;;AAIE;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;AADJ;;AAIE;EACE,qBAAA;EACA,cAAA,EAAA,eAAA;AADJ;;AAIE;EACE,0BAAA;AADJ","sourcesContent":[".dragger-submit-btn{\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n    padding: 12px 0px;\n}\n\n\n\n.file-upload-container {\n    border: 1px dashed #d9d9d9;\n    padding: 20px;\n    margin-bottom: 20px;\n    position: relative;\n    transition: border-color 0.3s;\n  }\n  \n  .file-upload-container:focus-within {\n    border-color: #1890ff; /* Change to your active border color */\n    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2); /* Optional shadow effect */\n  }\n  \n  .file-upload-container p {\n    margin: 0;\n  }\n  \n  .upload-button-container {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .uploaded-files-list {\n    margin: 0;\n    padding: 0;\n    list-style: none;\n    border: none;\n  }\n  \n  .uploaded-files-list li {\n    margin-bottom: 5px;\n    display: flex;\n    align-items: center;\n  }\n  \n  .uploaded-files-list a {\n    text-decoration: none;\n    color: #1890ff; /* Link color */\n  }\n  \n  .uploaded-files-list a:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
