// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tagContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.tags {
  border-radius: 50px;
}`, "",{"version":3,"sources":["webpack://./src/Main/Setting/Department/Department.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,2BAAA;EACA,mBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".tagContainer{\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.tags{\n    border-radius: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
